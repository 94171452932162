
import http from '../../HttpService'
import axios from '../../../axios'

const dataType = `sub_projects`
const baseService = http(dataType)
const additionalRequest = {
    delete: (id: string) => axios.put(`${dataType}/${id}`, { deleted: true }),
    getBookletList: (id: string) => axios.get(`${dataType}/${id}/booklets`),
    getAllowedBookletList: (id: string) => axios.get(`${dataType}/${id}/booklets_allowed`),
    getTagList: (id: string) => axios.get(`${dataType}/${id}/tags`),
    getDocumentList: (id: string) => axios.get(`${dataType}/${id}/documents`),
    postImportTask: (id: string, params: any) => axios.post(`${dataType}/${id}/import-task`, params, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }),
    postImportZip: (id: string, params: any) => axios.post(`${dataType}/${id}/import-zip`, params),
    getPermissionList: (id: string) => axios.get(`${dataType}/${id}/permissions`),
    getCurrentPermissionList: (id: string) => axios.get(`${dataType}/${id}/current_permissions`),
    getRessourceList: (id: string) => axios.get(`${dataType}/${id}/ressources`),
    getTaskList: (id: string, params: string = '') => axios.get(`${dataType}/${id}/tasks${params}`),
    getUsers: (id: string) => axios.get(`${dataType}/${id}/users`),
    getReportTaskList: (id: string, params: string = '') => axios.get(`${dataType}/${id}/report-tasks${params}`),
    getRexList: (id: string, params: string = '') => axios.get(`${dataType}/${id}/rexes${params}`),
    getMileStoneTasks: (id: string) => axios.get(`${dataType}/${id}/milestones`),
    postImportFile: (id: string, folder: string, params: any, progressCallback: any) => axios.post(`${dataType}/${id}/${folder}/import-file`, params, {
        onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
            if (totalLength !== null) {
                progressCallback(Math.round((progressEvent.loaded * 100) / totalLength));
            }
        }
    }),
    getPeriods: (id: string) => axios.get(`${dataType}/${id}/periods`)
}

export default Object.assign(baseService, additionalRequest)
