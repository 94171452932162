import { IonContent, IonHeader, IonPage, IonToolbar, useIonViewWillEnter, IonGrid, IonLabel } from "@ionic/react";
import React, { useState, useRef } from "react";
import "./UserList.scss";
import UserCard from "../../../components/User/UserCard";
import { userService } from '../../../services';
import NavigationMenu from "../../../components/Header/NavigationMenu/NavigationMenu";
import TitleOptionSearch from "../../../components/Header/TitleOptionSearch/TitleOptionSearch";
import AddFilter from "../../../components/Header/AddFilter/AddFilter";
import { personAddOutline } from "ionicons/icons";
import useFilter from "../../../hooks/useFilter";
import useSearch from "../../../hooks/useSearch";
import useDataFlow from "../../../hooks/useDataFlow";

const List = ({ list, getUserList }: any) => <>
  {list.map((user: any) => <UserCard key={user.id} user={user} getUserList={getUserList}/>)}
</>

const UserList: React.FC = () => {
  const isMountedRef = useRef<null | boolean>(null);

  const [isLoading, setIsLoading] = useState(true);
  // const [isSearching, setIsSearching]: any = useState();
  const [userListData, setUserListData] = useState([])
  const [userListDisplayed, setUserListDisplayed] = useState([])
  const { triggerSearch } = useSearch('user', setUserListDisplayed)
  const { triggerFilter, options } = useFilter('user')
  const { initDataFlow, dataFlow } = useDataFlow('user', userListData, [triggerFilter, triggerSearch])

  //Todo: creer useDataFlow pour standardiser mecanique
  const onFilterChange = (text: string) => {
    dataFlow([
      text,
      localStorage.getItem('search')!
    ])

    // dataFlow({
    //   filterName: text,
    //   searchText: localStorage.getItem('search')!,
    //   dataList: userListData
    // })
    // const updatedList = triggerFilter(text, userListData)
    // const displayedList = triggerSearch(localStorage.getItem('search')!, updatedList)
    // setUserListDisplayed(displayedList)
  }

  const onSearchChange = (text: string) => {
    // setIsSearching(text !== '')
    dataFlow([
      localStorage.getItem('userfilter')!,
      text
    ])

    // dataFlow({
    //   filterName: localStorage.getItem('userfilter')!,
    //   searchText: text,
    //   dataList: userListData
    // })
    // const updatedList = triggerFilter(localStorage.getItem('userfilter')!, userListData)
    // const displayedList = triggerSearch(text, updatedList)
    // setUserListDisplayed(displayedList)
  }

  // const dataFlow = ({ filterName, searchText, dataList }: any) => {
  //   const updatedList = triggerFilter(filterName, dataList)
  //   const displayedList = triggerSearch(searchText, updatedList)
  //   // setUserListDisplayed(displayedList)
  // }

  const getUserList = () => {
    userService.getList().then(response => {
      if(isMountedRef.current) {
        setUserListData(response.data)

        const searchText = ''
        localStorage.setItem('search', searchText)
        initDataFlow([
          localStorage.getItem('userfilter')!,
          searchText
        ], response.data)
      }
    setIsLoading(false)
    }).catch(e => {})
  }

  useIonViewWillEnter(() => {
    isMountedRef.current = true;
    document.title = `Utilisateurs - Canreport`
    getUserList()
    return () => isMountedRef.current = false;
  }, [])

  return (
    <IonPage className="ion-page" id="main-content">
      <IonHeader className="center-content">
        <IonToolbar>
          <NavigationMenu active="utilisateurs" />
          <TitleOptionSearch
            title="Utilisateurs"
            listCount={userListDisplayed.length || "0"}
            onSearchChange={onSearchChange}
          />
          <AddFilter
            type="user"
            addLink="/utilisateurs/creer"
            addLabel="Créer un utilisateur"
            addIcon={personAddOutline}
            onFilterChange={onFilterChange}
            options={options}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent className="IonPadding">
        <IonGrid className="center-content">
          <List list={userListDisplayed} getUserList={getUserList} />
          {/* <EmptyList list={userListDisplayed} isSearching={isSearching} /> */}
          {userListDisplayed.length === 0 && !isLoading && <div style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '150px',
          }}>
            <IonLabel style={{marginBottom: '20px'}}>
              Aucun utilisateur
            </IonLabel>
          </div>}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default UserList;
