
import http from '../../HttpService'

const dataType = `users`
const baseService = http(dataType)
const additionalRequest = {
    isAdmin: () => localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).roles.find((role: string) => role === "ROLE_ADMIN") : false,
    errorTest: () => new Error('not avaivable'),
    getUser: () => JSON.parse(localStorage.getItem('user') ?? '{}')
}

export default Object.assign(baseService, additionalRequest)
