import React from "react";
import {
    IonRow, IonCol, IonText
} from "@ionic/react";

export const REXRow: React.FC<any> = ({ rex }): any => {
    return <IonRow>
        <IonCol size={"1"}>{rex.tag}</IonCol>
        <IonCol size={"3"}>{rex.task}</IonCol>
        <IonCol size={"1"}>{rex.booklet}</IonCol>
        <IonCol size={"1"}>{rex.createdAt}</IonCol>
        <IonCol size={"1"}>{rex.user.fullName} - {rex.user.email}</IonCol>
        <IonCol size={"1"}>{rex.category}</IonCol>
        <IonCol size={"3"}><IonText style={{ whiteSpace: "pre-wrap" }}>{rex.comment}</IonText></IonCol>
        <IonCol size={"1"}>
            {rex.imageOne && <a href={rex.imageOne} target="_blank" rel={"noopener noreferrer"}><img src={rex.imageOne} alt="imageOne"/></a>}
            {rex.imageTwo && <a href={rex.imageTwo} target="_blank" rel={"noopener noreferrer"}><img src={rex.imageTwo} alt="imageOne"/></a>}
            {rex.imageThree && <a href={rex.imageThree} target="_blank" rel={"noopener noreferrer"}><img src={rex.imageThree} alt="imageOne"/></a>}
        </IonCol>
    </IonRow>
}
