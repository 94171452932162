import React, {useEffect, useMemo, useState} from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonButton,
    IonIcon,
    IonToolbar,
    IonGrid, IonRow, IonCol, IonSearchbar
} from "@ionic/react";
import {projectService, subProjectService} from "../../../services";
import NavigationMenu from "../../../components/Header/NavigationMenu/NavigationMenu";
import "./ReturnOfExperienceList.scss";
import {REXRow} from "../../../components/SubProject/ReturnOfExperience/REXRow";
import Select from "react-select";
import axios from "../../../axios";
import {download} from "ionicons/icons";

export const ReturnOfExperienceList: React.FC = ({ match }: any) => {
    const [subProject, setSubProject] = useState<any>({});
    const [project, setProject] = useState<any>({});

    const [bookletOptions, setBookletOptions]: any = useState([]);
    const [bookletSelected, setBookletSelected]: any = useState([]);

    const [rexes, setRexex] = useState<any[]>([]);

    const [categories, setCategories]: any = useState([]);
    const [categoriesSelected, setCategoriesSelected]: any = useState([]);

    const [searchText, setSearchText] = useState<string>("");

    useEffect(() => {
        subProjectService.getOne(match.params.subprojectid).then(({data: subProject}) => setSubProject(subProject));
        projectService.getOne(match.params.projectid).then(({data: project}) => setProject(project));

        subProjectService.getAllowedBookletList(match.params.subprojectid).then(({ data: bookletData }) => {
            const bookletList = Object.keys(bookletData).map((label: string) => {
                return {
                    label,
                    value: label,
                }
            })

            setBookletOptions(bookletList);
            setBookletSelected(bookletList);
        });
    }, [match.params.subprojectid]);

    useMemo(() => {
        subProjectService.getRexList(match.params.subprojectid, `?search=${searchText}&booklets=${bookletSelected?.map((e: any) => e.value)}&categories=${categoriesSelected?.map((e: any) => e.value)}`)
            .then(data => {
                setRexex(data.data.rexes)
                const categ = rexes.map(e => e.category).filter((value, index, self) => self.indexOf(value) === index);
                let categFormatted: any[] = categ.map(e => {
                    return {
                        value: e,
                        label: e
                    }
                });

                if(categories.length === 0) {
                    setCategories(categFormatted);
                    setCategoriesSelected(categFormatted);
                }
            })
    }, [bookletSelected, searchText, categoriesSelected]);

    const exportREX = () => {
        axios.get(`sub_projects/${match.params.subprojectid}/export-rex`, {
            responseType: 'blob'
        }).then(data => {
            const blobURL = window.URL.createObjectURL(data.data);
            const tempLink = document.createElement('a');
            tempLink.style.display = 'none';
            tempLink.href = blobURL;
            tempLink.setAttribute('download', 'REX.zip');
            tempLink.click();
            tempLink.remove();
        })
    }

    return <>
        <IonPage className="ion-page" id="main-content">
            <IonHeader className="center-content">
                <IonToolbar>
                    <NavigationMenu active="projets" backLabel={project.name} backLink={`/projet/${project.id}`} >
                        <h1 id="title-in-navigation-menu">{subProject.name}</h1>
                    </NavigationMenu>
                </IonToolbar>
            </IonHeader>
            <IonContent className="IonPadding">
                <IonGrid className="center-content">
                    <IonRow className={"ion-justify-content-between"}>
                        <IonRow className={"ion-justify-content-start"}>
                            <Select
                                value={bookletSelected}
                                isMulti
                                onChange={(val) => setBookletSelected(val)}
                                options={bookletOptions}
                                name="booklets"
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                            <Select
                                value={categoriesSelected}
                                isMulti
                                options={categories}
                                onChange={(val) => setCategoriesSelected(val)}
                                name="categories"
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </IonRow>
                        <IonButton onClick={exportREX}>
                            <IonIcon icon={download}>Exporter les REX</IonIcon>
                        </IonButton>
                    </IonRow>

                    <IonSearchbar
                        value={searchText}
                        style={{ width: "100%" }}
                        debounce={0}
                        onIonChange={(e) => setSearchText(e.detail.value ?? '')}
                        onKeyPress={(e) => e.key === "Enter" && setSearchText(searchText)}
                        onIonClear={() => setSearchText('')}
                        placeholder="Rechercher">
                    </IonSearchbar>
                </IonGrid>

                <IonGrid className="tasks-grid">
                    <IonRow className={"header"}>
                        <IonCol size={"1"}>Tag</IonCol>
                        <IonCol size={"3"}>Tâche</IonCol>
                        <IonCol size={"1"}>Carnet</IonCol>
                        <IonCol size={"1"}>Date d'ajout</IonCol>
                        <IonCol size={"1"}>Utilisateur</IonCol>
                        <IonCol size={"1"}>Catégorie</IonCol>
                        <IonCol size={"3"}>Commentaire</IonCol>
                        <IonCol size={"1"}>Images</IonCol>
                    </IonRow>
                    { rexes.length === 0 && <IonRow><IonCol size={"12"}>Aucun retour d'expérience n'a encore été renseigné</IonCol></IonRow>}
                    { rexes.map((e: any) => <REXRow key={e.id} rex={e}/>) }
                </IonGrid>
            </IonContent>
        </IonPage>
    </>;
}

export default ReturnOfExperienceList;
