import React, {useMemo, useState} from "react";
import SubProjectMenu from "../Menu/SubProjectMenu";
import {
    IonCard,
    IonCardHeader,
    IonRow,
    IonCardTitle,
    IonLabel,
    IonItem,
    IonButtons,
    IonButton,
    IonIcon,
    IonPopover, IonList
} from "@ionic/react";
import { ISubProject } from "../../Interface/SubProject";
import "./SubProjectCard.scss"
import { useHistory } from "react-router";
import {subProjectService, userService} from "../../services";
import {
    ellipsisVertical,
    folderOutline,
    list,
    listOutline,
    statsChartOutline,
} from "ionicons/icons";

const SubProjectCard: React.FC<any> = ({ projectId, subProject, getSubProjectList }: {
  projectId: string
  subProject: ISubProject,
  getSubProjectList: any
}) => {
  const history = useHistory()

  const [permissionList, setPermissionList]: any = useState([]);

  const getPermissionList = (subProjectId: any) => {
    subProjectService.getCurrentPermissionList(subProjectId)
        .then(({ data: permissionListData }: any) => {
          setPermissionList(Object.values(permissionListData));
        })
        .catch(console.log);
  }

    const [showPopover, setShowPopover] = useState<{
        open: boolean;
        event: Event | undefined;
    }>({
        open: false,
        event: undefined,
    });

  useMemo(() => {
    getPermissionList(subProject.id);
  }, []);

    const mileStones = (e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
        e.preventDefault()
        setShowPopover({ open: false, event: undefined })
        history.push(`/projet/${projectId}/sous-projet/${subProject.id}/milestones`);
    }

  const getButtons = () => {
      return (<><IonPopover
              isOpen={showPopover.open}
              event={showPopover.event}
              onDidDismiss={(e) =>
                  setShowPopover({ open: false, event: undefined })
              }
              cssClass="popover-menu"
          >
              <IonList>
                  <IonItem routerDirection='none' routerLink="#" onClick={(e) => {
                      e.preventDefault()
                      setShowPopover({ open: false, event: undefined })
                      history.push(`/projet/${projectId}/sous-projet/${subProject.id}/documentation`);
                  }}>
                      <IonIcon icon={folderOutline} slot="start" />
                      <IonLabel>Documentation</IonLabel>
                  </IonItem>
                  {permissionList.some((perm: any) => perm.booklet === null && perm.permissions.includes('progress_report')) && <><IonItem routerDirection='none' routerLink="#" onClick={(e) => {
                      e.preventDefault();
                      setShowPopover({ open: false, event: undefined })
                      history.push(`/projet/${projectId}/sous-projet/${subProject.id}/report`);
                  }}>
                      <IonIcon icon={list} slot="start" />
                      <IonLabel>Rapport d'avancement</IonLabel>
                  </IonItem>
                  <IonItem routerDirection='none' routerLink="#" onClick={(e) => {
                      e.preventDefault();
                      setShowPopover({ open: false, event: undefined })
                      history.push(`/projet/${projectId}/sous-projet/${subProject.id}/report-powerbi`);
                  }}>
                      <IonIcon icon={statsChartOutline} slot="start" />
                      <IonLabel>Rapport PowerBI</IonLabel>
                  </IonItem>
                  </>}
                  <IonItem routerDirection='none' routerLink="#" onClick={(e) => {
                      e.preventDefault()
                      setShowPopover({ open: false, event: undefined })
                      history.push(`/projet/${projectId}/sous-projet/${subProject.id}/rex`);
                  }}>
                      <IonIcon icon={list} slot="start" />
                      <IonLabel>Liste des commentaires</IonLabel>
                  </IonItem>

                  <IonItem routerDirection='none' routerLink="#" onClick={mileStones}>
                      <IonIcon icon={listOutline} slot="start" />
                      <IonLabel>Tâches jalon</IonLabel>
                  </IonItem>
              </IonList>
          </IonPopover>
          <IonButtons>
              <IonButton
                  style={{ position: "relative" }}
                  onClick={(e) =>
                      setShowPopover({ open: true, event: e.nativeEvent })
                  }
              >
                  <IonIcon slot="icon-only" icon={ellipsisVertical} />
              </IonButton>
          </IonButtons>
      </>);
  }

  return (
    <IonCard className="subproject-card">
      <IonCardHeader>
        <IonRow className="ion-align-items-center ion-justify-content-between">
          <IonCardTitle>
            <IonItem
              className="subproject-card__title"
              routerLink={`/projet/${projectId}/sous-projet/${subProject.id}`}
              routerDirection="none"
              lines="none"
            >
              <IonLabel className="subproject-card__name">
                {subProject.name}
              </IonLabel>
            </IonItem>
          </IonCardTitle>
          {/* isArchived={subProject.archived} */}
          {userService.isAdmin() && <SubProjectMenu projectId={projectId} subProjectId={subProject.id} getSubProjectList={getSubProjectList} />}
          {!userService.isAdmin() && getButtons()}
        </IonRow>
      </IonCardHeader>
    </IonCard>
  );
};

export default SubProjectCard;
