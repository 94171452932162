
import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    useIonViewWillEnter,
    IonGrid,
    IonRow,
    IonButton,
    IonIcon,
    IonLabel,
} from "@ionic/react";
import React, { useState,  useRef } from "react";
import NavigationMenu from "../../../components/Header/NavigationMenu/NavigationMenu";
import TitleOptionSearch from "../../../components/Header/TitleOptionSearch/TitleOptionSearch";
import { subProjectService, userService } from "../../../services";
import "./SubProjectAcces.scss";
import {reloadOutline, lockClosedOutline} from "ionicons/icons";
import AccessHeader from "../../../components/Access/AccessHeader";
import AccessCard from "../../../components/Access/AccessCard";
import Select from "react-select";

const List = ({ list, projectId, subProjectId, permissionList, bookletList }: any) => (
  <>
    {list.map((client: any) => {
        const { id, firstname, lastname } = client;

        const permissionTest = permissionList.length === 0 ? {} : permissionList.find((permission: any) => client.id === permission.user.id);
        const permissionValid = !!permissionTest ? permissionTest : {
          permissions: [],
          user: { id, firstname, lastname, }
        };

        return <AccessCard
            key={client.id}
            client={client}
            projectId={projectId}
            subProjectId={subProjectId}
            permission={permissionValid}
            bookletList={bookletList}
        />
    })}
  </>
);

const SubProjectAcces: React.FC = () => {
  const isMountedRef = useRef<null | boolean>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject]: any = useState({});
  const [subProject, setSubProject]: any = useState({});
  const [clientList, setClientList]: any = useState();
  const [clientListDisplayed, setClientListDisplayed]: any = useState([])
  const [clientSelected, setClientSelected]: any = useState();
  const [options, setOptions]: any = useState([]);
  const [bookletList, setBookletList]: any = useState([]);
  const [permissionList, setPermissionList]: any = useState([]);

  const getPermissionList = (subProjectId: any) => {
    subProjectService.getPermissionList(subProjectId)
      .then(({ data: permissionListData }: any) => {
        setPermissionList(Object.values(permissionListData));
      })
      .catch(console.log);
  }

  const addClient = (selected: any) => {
    setIsSubmitting(true)
    const newClientListDisplayed: any[] = [
        clientList.find((client: any) => client.id === selected.value),
        ...clientListDisplayed,
    ];

    setClientListDisplayed(newClientListDisplayed);
    setClientSelected(null);

    refreshOptions(clientList, newClientListDisplayed);
    setIsSubmitting(false)
  }

  const refreshOptions = (users: any[], usersDisplayed: any[]) => {
      setOptions(users.filter((user: any) => !usersDisplayed.some((userDisplayed: any) => userDisplayed.id === user.id)).map((user: any) => ({
          value: user.id,
          label: `${user.firstname} ${user.lastname}\n${user.email}`
      })))
  }

  useIonViewWillEnter(() => {
    isMountedRef.current = true;
    const projectId = window.location.pathname.split('/').reverse()[3]!
    const subProjectId = window.location.pathname.split('/').reverse()[1]!
    setProject({ id: projectId })
    setSubProject({ id: subProjectId })

    subProjectService.getOne(subProjectId)
      .then(({ data: subProjectData }: any) => {
        document.title = `${subProjectData.name} (accès) - Canreport`
        setSubProject(subProjectData)
      })
      .catch(console.log);

    subProjectService.getPermissionList(subProjectId)
        .then(({ data: permissionListData }: any) => {
            setPermissionList(Object.values(permissionListData))
            userService.getList()
                .then(({ data: clientListData }: any) => {
                    const users = clientListData.filter((user: any) => user.enabled);
                    const usersListDisplayed = users.filter((client: any) => Object.values(permissionListData).some((permission: any) => client.id === permission.user.id));

                    setClientList(users);
                    setClientListDisplayed(usersListDisplayed);

                    refreshOptions(users, usersListDisplayed)
                })
                .catch(console.log);
        })
        .catch(console.log);


    subProjectService.getBookletList(subProjectId)
      .then(({ data: bookletListData }: any) => {
        setBookletList(bookletListData)
      })
      .catch(console.log);

    setIsLoading(false);

    return () => isMountedRef.current = false;
  }, [options]);

  return (
    <IonPage className="ion-page" id="main-content">
      <IonHeader className="center-content">
        <IonToolbar>
          <NavigationMenu active="projets" backLabel={subProject.name} backLink={`/projet/${project.id}/sous-projet/${subProject.id}`} />
          <TitleOptionSearch
            title="Accès"
          />
        </IonToolbar>
      </IonHeader>
      <IonContent className="IonPadding">
        <IonGrid className="center-content">
          <IonRow className="ion-justify-content-end">
            {/* <form method="post" onSubmit={handleSubmit(onSubmit)} noValidate> */}
                <IonRow className="ion-align-items-center">
                  <div style={{
                    width: '300px',
                    zIndex: 9999,
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                    textOverflow: "string !important",
                  }}>
                    <Select
                      value={clientSelected}
                      onChange={setClientSelected}
                      placeholder="Accès au sous-projet"
                      width='200px'
                      menuColor='red'
                      options={options}
                    />
                  </div>
                <IonButton type="submit" disabled={isSubmitting} onClick={(e: any) => addClient(clientSelected)}>
                    <IonIcon
                      slot="start"
                      className={isSubmitting ? `rotate` : ``}
                      icon={isSubmitting ? reloadOutline : lockClosedOutline}
                    />
                    <IonLabel style={{ textTransform: "none" }}>
                      {isSubmitting ? "Chargement" : "Ajouter"}
                    </IonLabel>
                </IonButton>
              </IonRow>
            {/* </form> */}
          </IonRow>
          {clientListDisplayed.length > 0 && <AccessHeader />}
          {clientListDisplayed.length > 0 && <List list={clientListDisplayed} projectId={project.id} subProjectId={subProject.id} permissionList={permissionList} bookletList={bookletList} getPermissionList={getPermissionList} />}
          {/* <List list={permissionList} projectId={project.id} subProjectId={subProject.id} bookletList={bookletList} getPermissionList={getPermissionList} /> */}
          {/* <EmptyList list={permissionList} isSearching={isSearching} /> */}
          {permissionList.length === 0 && !isLoading && <div style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '150px',
          }}>
            <IonLabel style={{marginBottom: '20px'}}>
              Aucune permission définie
            </IonLabel>
          </div>}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SubProjectAcces;
