import React, {useState} from "react";
import useDate from "../../hooks/useDate";
import {
    IonBadge,
    IonButton,
    IonCardTitle,
    IonCol,
    IonIcon,
    IonLabel,
    IonModal,
    IonRow,
    IonText
} from "@ionic/react";
import {chatbubbleOutline} from "ionicons/icons";
import {taskService, userService} from "../../services";
import Chip from "./Chip";

const DetailledTaskModal: React.FC<any> = ({ task, taskData, onDismiss }) => {

    const { formatDate } = useDate();
    const [rexes, setRexes] = useState([]);

    const clickOnRexIcon = () => {
        if(task.rexCount > 0) {
            if(task.id !== undefined) taskService.getRexes(task.id).then(data => {
                setRexes(data.data);
            }).catch((e: any) => {})
        }
    }

    return (
        <>
            <IonModal isOpen={task !== undefined} onDidDismiss={onDismiss} cssClass={"modal-task-detail"}>
                {task && <>
                    <IonRow
                        className="ion-align-items-center ion-justify-content-between task-card__header">
                        <IonLabel className="task-card__title black">{taskData.content.projectName} - {taskData.content.name}</IonLabel>
                    </IonRow>
                    <IonRow
                        className="ion-align-items-center ion-justify-content-between task-card__header">
                        <IonCardTitle>
                            <IonLabel className="task-card__title black"
                                      style={{marginRight: '10px'}}>{task.hierarchicalNumber} {task.taskLabel}</IonLabel>
                        </IonCardTitle>
                        <Chip task={task}/>
                    </IonRow>
                    <IonRow className="ion-justify-content-between ion-margin-top text-dark">
                        <IonRow className={"ion-align-items-center"}>
                            <IonLabel
                                color={'dark'}
                                className={"text-dark"}>{task.booklet && `${task.booklet}`}{task.resources && task.resources[0] != "" && ` | ${task.resources}`}</IonLabel>
                        </IonRow>
                        <IonRow className={"ion-align-items-center"}>
                            <IonLabel color='dark' style={{
                                marginLeft: "8px",
                                textTransform: "capitalize"
                            }}>{formatDate(task?.startDate, 'D MMM HH:mm')} → {formatDate(task?.endDate, 'D MMM HH:mm')}</IonLabel>
                        </IonRow>
                    </IonRow>
                    {task.rexCount > 0 && <IonRow className={"ion-justify-content-center ion-align-items-center"}>
                        <IonCol size="11" style={{ padding: 0 }}>
                            <IonText color="dark" style={{fontWeight: 'bold'}}>
                                Commentaires
                            </IonText>
                        </IonCol>

                        <IonCol size="1">
                            <IonButton onClick={clickOnRexIcon} className={"button-clear"}>
                                <IonIcon color={"success"} icon={chatbubbleOutline} />
                            </IonButton>
                        </IonCol>
                    </IonRow>}
                    <IonText color="dark">
                        <h4 style={{fontWeight: 'bold', padding: '16px 0 8px 0'}}>Opérations</h4>
                    </IonText>
                    <IonText color="medium">
                        {task.operations && <p>{task.operations}</p>}
                        {!task.operations && <p>Aucune opération n'a été décrite</p>}
                    </IonText>
                    {task.extraInformations && task.extraInformations.length !== 0 && <>
                        <IonText color="dark">
                            <h4 style={{fontWeight: 'bold', padding: '16px 0 8px 0'}}>Informations complémentaires</h4>
                        </IonText>
                        {task.extraInformations.map((info: any, index: number) =>
                            <IonRow key={index}>
                                <IonCol size="3">
                                    <IonText color="dark">
                                        <h5>{info.label}</h5>
                                    </IonText>
                                </IonCol>
                                <IonCol>
                                    <IonText color="medium">
                                        <p>{info.value || 'non renseigné'}</p>
                                    </IonText>
                                </IonCol>
                            </IonRow>
                        )}
                    </>}
                    {task.mileStone && task.signature && task.signature.length !== 0 && <>
                        <IonText color="dark">
                            <h4 style={{fontWeight: 'bold', padding: '16px 0 8px 0'}}>Signature</h4>
                        </IonText>
                        <IonRow className={"ion-justify-content-start"}>
                            <IonCol size={"2"}>
                                <a target="_blank" href={task.signature.image}><img src={task.signature.image} height="72" alt="Signature"/></a>
                            </IonCol>
                            <IonCol>
                                <IonText color="dark">
                                    <h5>Par {task.signature.user}</h5>
                                    <h5>le {task.signature.createdAt}</h5>
                                </IonText>
                            </IonCol>
                        </IonRow>
                    </>}
                </>}
            </IonModal>
            <IonModal isOpen={rexes.length > 0} onDidDismiss={() => setRexes([])}>
                <p>Liste des commentaires</p>
                {rexes.map((rex: any) =>
                    <div key={rex.id}>
                        <hr/>
                        <IonRow className={"ion-justify-content-between"}>
                            <div>
                                <IonBadge color="medium">{rex.category}</IonBadge>
                                <h5 className={"h4"}><IonText style={{ whiteSpace: "pre-wrap" }}>{rex.comment}</IonText></h5>
                                <p className={"h6"}>Par <b>{rex.user.fullName}</b> le <b>{rex.createdAt}</b></p>
                            </div>
                            {(userService.isAdmin() || userService.getUser().email === rex.user.email) && <a style={{ color: "red", textDecoration: 'underline', cursor: 'pointer' }}>Supprimer</a>}
                        </IonRow>
                        <div>
                            {rex.imageOne && <a target="_blank" rel="noopener noreferrer" href={rex.imageOne}><img src={rex.imageOne} height="40" alt="imageOne"/></a>}
                            {rex.imageTwo && <a target="_blank" href={rex.imageTwo} rel="noopener noreferrer"><img src={rex.imageTwo} height="40" alt="imageTwo"/></a>}
                            {rex.imageThree && <a target="_blank" href={rex.imageThree} rel="noopener noreferrer"><img src={rex.imageThree} height="40" alt="imageThree"/></a>}
                        </div>
                    </div>
                )}
                <IonButton onClick={() => setRexes([])}>Fermer</IonButton>
            </IonModal>
        </>
    );
}

export default DetailledTaskModal;
