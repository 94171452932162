import React from "react";
import {
    IonRow, IonCol, IonIcon
} from "@ionic/react";
import {arrowForwardOutline, trendingDownOutline, trendingUpOutline} from "ionicons/icons";

export const TaskRow: React.FC<any> = ({ task }): any => {
    const todayGap = task.progress_in_hours - task.expectedToday;
    const trend = todayGap - (task.yesterdayProgress - task.expectedYesterday);

    const getLabelStyle = (nbr: string) => {
        const levelCount = nbr.split(".").length;
        return {
            textAlign: 'left',
            fontSize: (22- (levelCount*2))+'px',
            fontWeight: (900 - levelCount*100)
        }
    };

    const round = (nbr: number) => (Math.round(nbr * 10) / 10);
    const dateFormatted = (date: string) => new Date(date).toLocaleDateString();
    const formatHours = (nbr: number) => round(nbr) + " h";

    const trendIcon = () => {
        const trends = [[trendingUpOutline, '#32ff6d'], [trendingDownOutline, '#ff5b5a'], [arrowForwardOutline, '#777']];
        let trendIndex = 0;
        if(trend < 0) {
            trendIndex = 1;
        } else if(trend === 0) {
            trendIndex = 2;
        }

        return <IonIcon style={{
            height: "18px",
            width: "18px",
            color: trends[trendIndex][1]
        }} icon={trends[trendIndex][0]} />
    };

    return <IonRow>
        <IonCol style={getLabelStyle(task.hierarchical_number)} size={"3"}><p>{task.hierarchical_number} - { task.label }</p></IonCol>
        <IonCol size={"1"}><p>{ formatHours(task.expectedToday) }</p></IonCol>
        <IonCol size={"1"}><p>{ formatHours(task.progress_in_hours) }</p></IonCol>
        <IonCol size={"1"}><p>{ formatHours(todayGap) }</p></IonCol>
        <IonCol size={"1"}><p>{ trendIcon() } { formatHours(trend) }</p></IonCol>
        <IonCol size={"1"}><p>{ dateFormatted(task.expectedBeginning) }</p></IonCol>
        <IonCol size={"1"}><p>{ task.realBeginning && dateFormatted(task.realBeginning) }</p></IonCol>
        <IonCol size={"1"}><p>{ dateFormatted(task.expectedEnding) }</p></IonCol>
        <IonCol size={"1"}><p>{ task.progress == 100 ? dateFormatted(task.realEnding) : ''}</p></IonCol>
        <IonCol size={"1"}><p>{ formatHours(task.total_work) }</p></IonCol>
    </IonRow>
}
