import React, {useEffect, useState} from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonDatetime,
    IonToolbar,
    IonGrid, IonRow, IonCol, IonSearchbar, IonButton, IonIcon
} from "@ionic/react";
import {projectService, subProjectService} from "../../../services";
import NavigationMenu from "../../../components/Header/NavigationMenu/NavigationMenu";
import "./ProgressReportList.scss";
import {TaskRow} from "../../../components/SubProject/ProgressReport/TaskRow";
import ArrayService from "../../../services/ArrayService";
import { print } from "ionicons/icons";
import axios from "../../../axios";
import Select from "react-select";

export const ProgressReportList: React.FC = ({ match }: any) => {
    const [subProject, setSubProject] = useState<any>({});
    const [project, setProject] = useState<any>({});

    const [searchText, setSearchText] = useState<string>("");
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [periodOptions, setPeriodOptions] = useState<any[]>([]);
    const [period, setSelectedPeriod] = useState<any>();

    const [tasks, setTasks] = useState<any[]>([]);

    useEffect(() => {
        subProjectService.getOne(match.params.subprojectid).then(({data: subProject}) => setSubProject(subProject));
        projectService.getOne(match.params.projectid).then(({data: project}) => setProject(project));
        subProjectService.getPeriods(match.params.subprojectid).then(({data}) => {
            let currentPeriod = null;

            const periodOptions = data.map((e: any) => {
                const obj = {
                    label: e.startDate + " - " + e.endDate,
                    value: e.endDate
                };

                if(e.isCurrentPeriod) currentPeriod = obj
                return obj;
            })

            setPeriodOptions(periodOptions)
            setSelectedPeriod(currentPeriod)
        });
    }, [match.params.subprojectid])

    const updateTaskList = () => {
        if(period == undefined || selectedDate == undefined) return;

        subProjectService.getReportTaskList(match.params.subprojectid, `?date=${selectedDate.toLocaleDateString() + ' ' + period.value}&search=${searchText}`)
            .then(data => {
                const tasks = data.data.tasks;
                const sortedTasks = ArrayService.sortHierarchical(tasks,"hierarchical_number");
                setTasks(sortedTasks);
            })
    }

    useEffect(updateTaskList, [])
    useEffect(updateTaskList, [match.params.subprojectid, searchText, selectedDate, period]);

    const downloadExportPdf = () => {
        axios.get(`sub_projects/${match.params.subprojectid}/export-report-tasks?date=${selectedDate.toLocaleDateString() + ' ' + selectedDate.toLocaleTimeString()}&search=${searchText}`, {
            responseType: 'blob'
        }).then(data => {
            const blobURL = window.URL.createObjectURL(data.data);
            const tempLink = document.createElement('a');
            tempLink.style.display = 'none';
            tempLink.href = blobURL;
            tempLink.setAttribute('download', 'export-rapport.pdf');
            tempLink.click();
            tempLink.remove();
        })
    }

    return (
        <IonPage className="ion-page" id="main-content">
            <IonHeader className="center-content">
                <IonToolbar>
                    <NavigationMenu active="projets" backLabel={project.name} backLink={`/projet/${project.id}`} >
                        <h1 id="title-in-navigation-menu">{subProject.name}</h1>
                    </NavigationMenu>
                </IonToolbar>
            </IonHeader>
            <IonContent className="IonPadding">
                <IonGrid className="center-content">
                    <IonDatetime
                        displayFormat="DD/MM/YYYY"
                        onIonChange={(e: CustomEvent) => setSelectedDate(new Date(e.detail.value))}
                        displayTimezone={"utc"}
                        cancelText={"Annuler"}
                        doneText={"Valider"}
                        placeholder="Choisissez une date"/>

                    <Select
                        value={period}
                        onChange={(val) => setSelectedPeriod(val)}
                        options={periodOptions}
                        name="booklets"
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />

                    <IonSearchbar
                        value={searchText}
                        style={{ width: "100%" }}
                        onIonChange={(e) => setSearchText(e.detail.value ?? '')}
                        onKeyPress={(e) => e.key === "Enter" && setSearchText(searchText)}
                        onIonClear={() => setSearchText('')}
                        placeholder="Rechercher">
                    </IonSearchbar>
                </IonGrid>

                <IonRow className={"tasks-grid ion-justify-content-end"}>
                    <IonButton onClick={downloadExportPdf}>
                        <IonIcon icon={print}>Télécharger le rapport au format PDF</IonIcon>
                    </IonButton>
                </IonRow>

                <IonGrid className="tasks-grid">
                    <IonRow className={"header"}>
                        <IonCol size={"3"}>Tâche</IonCol>
                        <IonCol size={"1"}>Avancement prév</IonCol>
                        <IonCol size={"1"}>Avancement réel</IonCol>
                        <IonCol size={"1"}>Ecart</IonCol>
                        <IonCol size={"1"}>Tendance</IonCol>
                        <IonCol size={"1"}>Date début prév</IonCol>
                        <IonCol size={"1"}>Date début réel</IonCol>
                        <IonCol size={"1"}>Date fin prév</IonCol>
                        <IonCol size={"1"}>Date fin réel</IonCol>
                        <IonCol size={"1"}>Travail</IonCol>
                    </IonRow>
                    { tasks.length === 0 && <IonRow><IonCol size={"12"}>Aucune donnée</IonCol></IonRow>}
                    { tasks.map((e: any) => <TaskRow key={e.id} task={e}/>) }
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}
